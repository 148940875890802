import React from "react"

function Dots(props) {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="23"
      viewBox="0 0 29 23"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={props.fill} transform="translate(3)">
          <path d="M0 0H4.5V4.5H0z"></path>
          <path d="M0 9H4.5V13.5H0z"></path>
          <path d="M9 0H13.5V4.5H9z"></path>
          <path d="M9 9H13.5V13.5H9z"></path>
          <path d="M18 0H22.5V4.5H18z"></path>
          <path d="M18 9H22.5V13.5H18z"></path>
          <path d="M0 18H4.5V22.5H0z"></path>
          <path d="M9 18H13.5V22.5H9z"></path>
          <path d="M18 18H22.5V22.5H18z"></path>
        </g>
      </g>
    </svg>
  )
}

export default Dots
