import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

var classNames = require("classnames")

import "./CopyToClipboard.scss"

function CopyToClipboard({ email }) {
  const [tooltipText, setTooltipText] = useState("Copy to clipboard")

  function copyClick(e) {
    e.preventDefault()
    let text = email
    navigator.clipboard.writeText(text).then(
      function () {
        /* clipboard successfully set */
        setTooltipText("Copied to clipboard!")
      },
      function () {
        /* clipboard write failed */
      }
    )
  }
  function outFunc() {
    setTooltipText("Copy to clipboard")
  }
  return (
    <div className="tooltip" onMouseOut={outFunc}>
      <span className="tooltiptext" id="myTooltip">
        {tooltipText}
      </span>
      <a
        aria-label="copy email to clipboard"
        role="button"
        onClick={event => {
          copyClick(event)
        }}
      >
        Email Us
      </a>
    </div>
  )
}

export default CopyToClipboard
